import React from "react"
import { useIdentityContext } from "react-netlify-identity"
import { NavLink } from "theme-ui"
import { Link } from "gatsby"
import Avatar from "@assets/avatar.svg"

export default ({ colorMode = "light" }) => {
  const { isLoggedIn } = useIdentityContext()

  return (
    <NavLink
      as={Link}
      to={isLoggedIn ? "/user-profile" : "/login"}
      variant={`links.avatar.${colorMode}`}
    >
      <Avatar />
    </NavLink>
  )
}
