/** @jsx jsx */
import { jsx, useThemeUI } from "theme-ui"
import { Link } from "gatsby"

export default ({variant = 'nav', ...props}) => {
  const { theme } = useThemeUI()

  return (
    <Link
      {...props}
      activeClassName="active"
      sx={{
        ...theme.links[variant],
      }}
    />
  )
}
