import React from "react"
import { Flex, NavLink } from "theme-ui"
import Link from "@common/Link"

export default ({colorMode}) => (
  <Flex as="nav" variant="layout.mainNavigation">
    <NavLink as={Link} to="/calendar" variant={`links.mainNav.${colorMode}`}>Kalendarz</NavLink>
    <NavLink as={Link} to="/faq" variant={`links.mainNav.${colorMode}`}>FAQ</NavLink>
    <NavLink as={Link} to="/contact" variant={`links.mainNav.${colorMode}`}>Kontakt</NavLink>
  </Flex>
)
