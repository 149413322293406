import React from "react"
import { NavLink } from "theme-ui"
import { Link } from "gatsby"
import LogoSVG from "@assets/dworld.svg"

export default ({
  colorMode = "light",
  siteTitle,
  variant = "mainLogo",
  ...props
}) => (
  <NavLink
    as={Link}
    aria-label="Dworld.pl powrót do strony głównej"
    to="/"
    variant={`links.mainLogo.${colorMode}`}
    {...props}
  >
    <LogoSVG alt={siteTitle} />
  </NavLink>
)
